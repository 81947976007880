.headerWrapper {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1000;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: black;
  }
  
  .imageWrapper {
    flex-shrink: 0;
  }
  
  .logoLink {
    display: block;
  }
  
  .logo {
    width: 200px; 
    height: 90px;
  }
  
  .linkWrapper {
    display: flex;
    align-items: center;
  }
  
  .phone {
    margin-right: 3rem;
    color: turquoise; 
    font-size: 25px;
  }

  .phoneIcon {
    color: turquoise;
    width: 50px;
  }
  
  .desktopLinks {
    display: flex;
    gap: 20px;
  }
  
  .navLink {
    text-decoration: none;
    color: turquoise; 
    transition: color 0.3s ease-in-out;
    font-size: 30px;
  }
  
  .navLink:hover {
    color: white;
    border-bottom: 2px solid turquoise;
  }
  
  .mobileDropdownTrigger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 5rem;
    transition: transform 0.3s ease-in-out;
    display: none;
  }
  
  .mobileDropdownTrigger.open {
    transform: rotate(180deg);
  }
  
  .mobileDropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    top: 55%;
    right: 0;
    padding: 5px;
    background-color: black;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    border-radius: .5rem;
  }
  
  .mobileDropdown.open {
    transform: scaleY(1);
    border: 2px solid turquoise;
  }

  .menu {
    font-size: 5rem;
  }

  @media (max-width: 393px) {
    .desktopLinks {
      display: none;
    }
  
    .mobileDropdownTrigger {
      display: inline-block;
    }
    .phone {
      font-size: 15px;
    }
    .logo {
      width: 150px;
      height: 70px;
    }
    .navLink {
      font-size: 20px;
    }
    .mobileDropdown.mobileDropdown.open {
        border-top-left-radius: 3rem;
    }
  }
  
  @media  (max-width: 435px) {
    .desktopLinks {
      display: none;
    }
  
    .mobileDropdownTrigger {
      display: inline-block;
    }
    .phone {
        font-size: 15px;
    }
    .navLink {
      font-size: 25px;
    }
    .mobileDropdown.open {
        border-top-left-radius: 1.5rem;
    }
  }

  @media (min-width: 436px) and (max-width: 820px) {
    .desktopLinks {
      display: none;
    }

    .phone {
        margin-right: 10rem;
    }
  
    .mobileDropdownTrigger {
      display: inline-block;
    }
    .navLink {
      font-size: 25px;
    }
  }
  
  @media (min-height: 393px) and (max-width: 1040px) and (orientation: landscape){
    .desktopLinks {
      display: none;
    }
  
    .mobileDropdownTrigger {
      display: inline-block;
    }
    .navLink {
      font-size: 35px;
    }
    .phone {
        margin-right: 9rem;
    }
  }


  @media (min-height: 1558px) and (max-width: 975px){
    .desktopLinks {
      display: none;
    }
  
    .mobileDropdownTrigger {
      display: inline-block;
    }
    .navLink {
      font-size: 35px;
    }
    .phone {
        margin-right: 9rem;
    }

    .menu {
        width: 3rem;
    }
  }

  @media (min-width: 1041px){
    .mobileDropdown {
      display: none;
    }
  }