.homeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
}
.initialContent {
    width: 100%;
    height: 100%;
    background-image: url('../Images/flooring.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.contextWrapper{
    width: 50%;
    height: 50%;
    align-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: .5rem;
}

.buttonSection {
    display: flex;
    width: 100%;
    height: fit-content;
    align-content: center;
    justify-content: center;
    margin: 5px;
}


.homeButton,
.flooringButton {
    width: fit-content;
    height: fit-content;
    margin: 5px;
    padding: 5px;
    background-color: turquoise;
    border-radius: .5rem;
    color: black;
    text-decoration: none;
}


.flooringButton:hover,
.homeButton:hover {
    color: turquoise;
    background-color: black;
}

@media (max-width: 435px) {
    .contextWrapper {
        width: 80%;
        height: 30%;
    }
    .buttonSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}