.selfLevelingImageContainer h1 {
    color: turquoise;
    font-size: 25px;
}

.selfLevelingImagesContainer {
    max-width: 70%;
    max-height: 60%;
    image-rendering: -webkit-optimize-contrast; 
    image-rendering: crisp-edges; 
  }


.selfLevelingImagesContainer {
    max-width: 80%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-root {
    height: 100vh;
}

.carouselContainer {
    width: 90%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
}
.carouselContainer .carousel {
    height: 70%;
}

.selfLevelingImageContainer {
    width: 70%;
    height: 90%;
    border-radius: 10px;
    background-color: white;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .selfLevelingImageContainer {
        width: 90%;
        height: 50%;
    }
    
    .selfLevelingImageContainer .carousel-root {
        width: 100%;
        height: 100vh;
    }
    .selfLevelingImageContainer .carouselContainer {
        width: 100%;
        height: 80%;
    }
    .selfLevelingImageContainer .carouselContainer .carousel {
        height: 30%;
    }
}

@media (orientation: landscape) and (max-width: 1066px) {
    .selfLevelingImageContainer {
        width: 90%;
        height: 80%;
    }

    .selfLevelingImageContainer .carouselContainer .carousel {
        height: 45%;
    }
}

@media (min-height: 1558px) and (max-width:975px){
    .selfLevelingImageContainer {
        height: 60%;
        padding: 0;
        margin: 0;
        white-space: none;
    }
}