.woodCarouselContainer {
    width: 100%;
    height: 70%;
}

@media (max-width: 768px) {
    .woodCarouselContainer {
        width: 100%;
    }
    .woodCarouselContainer .carousel {
        height: 50%;
    }
    .woodCarouselContainer .carousel-root {
        height: 100%;
    }
    .woodCarouselContainer .carousel-slider {
        height: 80%;
    }

}

@media (orientation: landscape) and (max-width: 1066px) {
    .imageContainer .woodCarouselContainer {
        width: 100%;
        height: 80%;
    }
    .woodCarouselContainer .carousel {
        height: 70%;
    }
    .woodCarouselContainer .carousel-slider {
        height: 60%;
    }
    .woodCarouselContainer .carousel .slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .woodCarouselContainer .carousel .slide img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}