.footerWrapper {
    position: sticky;
    display: flex;
    width: 100%;
    height: 50px;
    background-color: black;
    justify-content: center;
    text-align: center;
    z-index: 999;}

.footer {
    background-color: black;
    color: white;
    text-align: center;
}