.flooringWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
}
.flooringContent {
    width: 100%;
    height: 70%;
    border-bottom: 2px solid black;
}
.reviewSection {
    width: 100%;
    height: 30%;
}

iframe {
    margin: 5px;
    width: 100%;
    padding: 5px;
}

.lpvModalButton,
.selfLevelingModalButton,
.woodModalButton {
    width: fit-content;
    color: turquoise;
    height: 100%;
    border-radius: 5px;
    font-size: 1.2em;
    margin: 5px;
    padding: 5px;
    border: none;
    background-color: black;
    cursor: pointer;
}

.lpvContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.sellLvp {
    color: turquoise;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 400;
    margin: 25px;
    background-color: black;
    border-radius: .5rem;
    padding: 5px;
}

.leaveReview {
    text-decoration: none;
    color: turquoise;
    background-color: black;
    border-radius: .5rem;
    padding: 5px;
}

.leaveReview:hover,
 .sellLvp:hover {
    color: black;
    background-color: turquoise;
}

@media (min-height: 1558px) and (max-width: 975px){
    h1, h2 {
        font-size: 35px;
    }
}