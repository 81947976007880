.App {
  font-family:'Times New Roman', Times, serif;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}



.App-header {
  background-image: linear-gradient(to left, #ece9e6, #ffffff);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: 0;
  padding: 0;
}


