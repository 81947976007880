.imageSliderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .carousel-root {
    width: 100%;
    height: 100%;
  }
  .carousel-slider {
    width: 100%;
    height: 80%;
  }
  .closeButtonContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .closeButton {
    background-color: #fff;
    color: red;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  
.imagesContainer {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .imageContainer {
    width: 70%;
    height: 90%;
    border-radius: 10px;
    background-color: white;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .carousel .control-dots .dot.selected {
    background: #00ffff;  
  }

  .carousel-status {
    width: 100%;
  }

  .carouselContainer {
    width: 100%;
    height: 80%;
  }

  .imageContainer img {
    max-width: 100%;
    max-height: 80%;
    image-rendering: -webkit-optimize-contrast; 
    image-rendering: crisp-edges; 
  }

  .imageContainer h1 {
    color: turquoise;
    font-size: 25px;
  }

  @media (max-width: 768px) {
    .imageContainer {
      width: 90%;
      height: 50%;
    }
    .imageContainer .carouselContainer .carousel{
        width: 100%;
        height: 30%;
    }
    .carouselContainer.carousel-root {
        width: 100%;
        height: 80%;
    }
    .carouselContainer .carousel-slider {
        width: 100%;
        height: 90%;
    }
  }

  @media (max-width: 820px) and (min-width: 769px) {
    .imageContainer {
      width: 90%;
      height: 50%;
      padding: 0;
      margin: 0;
    }
    .imageContainer .carouselContainer {
        width: 100%;
        height: 80%;
    }
    .imageContainer .carousel-root {
        width: 100%;
        height: 100%;
    }
    .carousel-slider {
        width: 100%;
        height: 80%;
    }
}

@media (orientation: landscape) and (max-width: 1066px) {
    .imageContainer {
        width: 90%;
        height: 90%;
        padding: 0;
        margin: 0;
    }
    .imageContainer .carouselContainer {
        width: 100%;
        height: 80%;
    }
    .carouselContainer .carousel {
        height: 100%;
    }
    .carouselContainer .carousel-root  {
        width: 100%;
        height: 100%;
    }
    .carouselContainer .carousel-root .carousel-slider {
        height: 100%;
    }
    
}

@media (min-height: 1558px) and (max-width:975px){
    .imageContainer {
        height: 60%;
        padding: 0;
        margin: 0;
        white-space: none;
    }
    .imageContainer .carouselContainer {
        width: 100%;
        height: 100%;
    }
    .imageContainer .carouselContainer {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    .carousel-root {
        max-height: 100%;  
      }
}