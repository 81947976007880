.contactUsWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.contactUsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: fit-content;
    color: white;
    padding: 5px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    color: turquoise;
    padding: 10px;
    border-radius: .5rem;
    background-color: black;
}

.message {
    color: turquoise;
}

.messageReceived {
    color: turquoise;
    width: 50%;
    height: fit-content;
    padding: 10px;
    background-color: black;
    border-radius: .5rem;
}

.contactForm input {
    width: 50%;
    height: 30px;
    font-size: 1.2rem;
    margin: 10px;
    padding: 5px;
    color: black;
    border-radius: .5rem;
    border: none;
}

.contactForm textarea {
    width: 50%;
    height: 100px;
    margin: 10px;
    padding: 5px;
    border-radius: .5rem;
    border: none;
    max-width: 100%;
}

.contactForm button {
    width: 50%;
    height: fit-content;
    margin: 10px;
    padding: 5px;
    border-radius: .5rem;
    border: none;
    background-color: turquoise;
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    cursor: pointer;
}

.contactForm button:hover {
    background-color: white;
    color: turquoise;
}