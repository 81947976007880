.aboutWrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-top: 8rem;
    pointer-events: none;
}

.backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.aboutContent {
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 20px auto;
    width: 90%;
    color: white;
    pointer-events: auto;
}

.aboutContent a {
    color: turquoise;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0 20px;
    background-color: grey;
    border-radius: .5rem;
    padding: 5px;
}

.aboutContent a:hover {
    color: white;
}

@media (orientation: landscape) {
    .aboutWrapper {
        height: 100%;
    }
    .backgroundVideo {
        height: 100%;
    }
}

@media (min-height: 1558px) and (max-width: 975px) {
    .aboutWrapper {
        height: 100vh;
    }
    .backgroundVideo {
        height: 100%;
    }
}